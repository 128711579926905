import styled from 'styled-components';
import { ContentWrapper, SectionWrapper } from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin: 13.5rem auto 8rem;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
`;

export const Column = styled.article`
  flex: 1 0 100%;
  max-width: 670px;
  color: ${({ theme }) => theme.color.dark};

  ${({ theme }) => theme.mq.tablet} {
    flex: 1 0 480px;
    margin-right: 3rem;
  }
`;

export const Title = styled.h1`
  margin: 0 0 2.8rem;
  font-size: 3rem;
  line-height: 4.4rem;
`;

export const Text = styled.div`
  h2 {
    margin: 0 0 1rem;
    font-size: 1.8rem;
    line-height: 3rem;
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    line-height: 3rem;
  }

  p {
    margin: 0 0 2rem;
    font-size: 1.3rem;
    line-height: 2.8rem;
  }

  ul,
  ol {
    margin: 0 0 2rem;
    font-size: 1.3rem;
    line-height: 2.8rem;
    padding-inline-start: 2rem;

    li {
      margin: 0 0 0.6rem;
    }
  }

  a {
    color: ${({ theme }) => theme.color.dark};
    font-weight: 600;

    :hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

export const Aside = styled.aside`
  align-self: flex-end;
  width: 260px;
  height: 200px;
  margin: 0 auto 4rem;
  padding: 0.6rem;
  border: 1px solid #eaebed;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.bright};
  color: ${({ theme }) => theme.color.dark};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.a.attrs({
  activeStyle: { fontWeight: 600, backgroundColor: '#d9dce5' },
})`
  display: flex;
  position: relative;
  align-items: center;
  padding: 1.6rem 2rem;
  transition: background-color 0.2s ease-in-out;
  border-radius: 8px;
  background-color: #fff;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.3rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;

  :hover {
    background-color: #d9dce5;
    font-weight: 600;
  }

  ::before {
    content: '';
    width: 20px;
    height: 12px;
    background: url('/icons/privacyArrow.svg') no-repeat;
  }
`;
