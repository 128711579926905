import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Head from 'views/Head';
import Link from 'components/Link';
import {
  Wrapper,
  Content,
  Column,
  Title,
  Text,
  Aside,
  List,
  Item,
} from './styles';

const PrivacyTemplate: FC<PrivacyQuery> = ({ data }) => {
  const {
    html,
    frontmatter: { type, title },
  } = data.markdownRemark;
  const documents = data.allMarkdownRemark.edges;

  return (
    <>
      <Head title={title} />
      <Wrapper id={type}>
        <Content>
          <Column>
            <Title as="h1">{title}</Title>
            <Text
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </Column>
          <Aside>
            <List>
              {documents.map(({ node }) => (
                <li key={node.id}>
                  <Item as={Link} to={node.fields.slug}>
                    {node.frontmatter.title}
                  </Item>
                </li>
              ))}
            </List>
          </Aside>
        </Content>
      </Wrapper>
    </>
  );
};

interface PrivacyQuery {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
        type: string;
      };
    };
    allMarkdownRemark: {
      edges: {
        node: {
          id: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            type: string;
          };
        };
      }[];
    };
  };
}

export const query = graphql`
  query PrivacyTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        type
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "document" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            type
            title
          }
        }
      }
    }
  }
`;

export default PrivacyTemplate;
